const COMMON_MODAL_PROPS = {
  wrapperClassName: 'modal-wrap center-items',
  className: ['overflow-visible', 'overflow-visible-with-child',
    'modal-container--narrow modal-container--narrow--400',
    'modal-container--narrow--center', 'modal-container--narrow--auto-height',
    'modal-container--narrow--auto-max-height'],
};

const modalList = {
  FrozenModal: {
    name: 'FrozenModal',
    priority: 0,
  },
  WisePKIntrusiveModal: {
    priority: 2,
    modalProps: {
      ...COMMON_MODAL_PROPS,
    },
    props: {
      notHandleOutside: true,
    },
  },
  RequestPMSetupNotifications: {
    name: 'RequestPMSetupNotifications',
    priority: 3,
    modalProps: {
      ...COMMON_MODAL_PROPS,
    },
  },
  SessionPromptModal: {
    name: 'SessionPromptModal',
    priority: 4,
  },
  ExpiredModal: {
    name: 'ExpiredModal',
    priority: 5,
  },
  MixRequestsIntrusive: {
    name: 'MixRequestsIntrusive',
    priority: 6,
    props: {
      notHandleOutside: true,
    },
    modalProps: {
      ...COMMON_MODAL_PROPS,
    },
  },
  VerificationRequestIntrusive: {
    name: 'VerificationRequestIntrusive',
    priority: 7,
    props: {
      notHandleOutside: true,
    },
    modalProps: {
      ...COMMON_MODAL_PROPS,
    },
  },
  VerificationCallRequestIntrusive: {
    name: 'VerificationCallRequestIntrusive',
    priority: 8,
    props: {
      notHandleOutside: true,
    },
    modalProps: {
      ...COMMON_MODAL_PROPS,
    },
  },
  WelcomeModal: {
    name: 'WelcomeModal',
    priority: 9,
    modalProps: {
      wrapperClassName: 'modal-wrap center-items',
      className: ['modal-container--center-items',
        'modal-container--narrow--400', 'modal-container--narrow--center',
        'modal-container--narrow--auto-height'],
    },
  },
  WellDoneModal: {
    name: 'WellDoneModal',
    priority: 10,
    modalProps: {
      wrapperClassName: 'modal-wrap center-items',
      className: ['modal-container--center-items',
        'modal-container--narrow--400', 'modal-container--narrow--center',
        'modal-container--narrow--auto-height'],
    },
  },
  WelcomeBackWriter: {
    name: 'WelcomeBackWriter',
    priority: 11,
    modalProps: {
      wrapperClassName: 'modal-wrap center-items',
      className: ['modal-container--center-items',
        'modal-container--narrow--400', 'modal-container--narrow--center',
        'modal-container--narrow--auto-height'],
    },
  },
  ViewAwesome: {
    name: 'ViewAwesome',
    priority: 12,
    modalProps: {
      wrapperClassName: 'modal-wrap center-items',
      className: ['modal-container--center-items',
        'modal-container--narrow--400', 'modal-container--narrow--center',
        'modal-container--narrow--auto-height'],
    },
  },
  TermsConditions: {
    priority: 13,
  },
  TermsOfUse: {
    priority: 14,
  },
  ProfileSetUp: {
    name: 'ProfileSetUp',
    priority: 15,
  },
  VerificationRequired: {
    name: 'VerificationRequired',
    priority: 16,
    modalProps: {
      ...COMMON_MODAL_PROPS,
    },
  },
  AddressRequest: {
    name: 'AddressRequest',
    priority: 17,
    modalProps: {
      className: ['modal-container--center-items',
        'modal-container--narrow--400',
        'modal-container--narrow--center',
        'modal-container--narrow--auto-height'],
    },
  },
  SubjectsModal: {
    name: 'SubjectsModal',
    priority: 18,
  },
  MajorSubjectsForm: {
    name: 'MajorSubjectsForm',
    priority: 19,
    props: {
      notHandleOutside: true,
    },
  },
  OtherSubjectsModal: {
    name: 'OtherSubjectsModal',
    priority: 20,
    props: {
      notHandleOutside: true,
    },
  },
  ThanksModal: {
    name: 'ThanksModal',
    priority: 21,
    props: {
      notHandleOutside: true,
    },
  },
  PredictedCapacityWriterPoll: {
    name: 'PredictedCapacityWriterPoll',
    priority: 22,
    props: {
      notHandleOutside: true,
    },
    modalProps: {
      className: 'ta-center poll-modal',
      small: true,
    },
  },
  ScheduleSession: {
    name: 'ScheduleSession',
    priority: 23,
    props: {
      notHandleOutside: true,
    },
    modalProps: {
      className: ['session-details-modal'],
    },
  },
  NewEstimate: {
    name: 'NewEstimate',
    priority: 24,
  },
  NTInfoModal: {
    name: 'NTInfoModal',
    priority: Infinity,
    modalProps: {
      ...COMMON_MODAL_PROPS,
    },
  },
  NTIntroductionModal: {
    name: 'NTIntroductionModal',
    priority: Infinity,
    modalProps: {
      ...COMMON_MODAL_PROPS,
    },
  },
  VerificationWithdrawalInProgress: {
    name: 'VerificationWithdrawalInProgress',
    priority: Infinity,
    modalProps: {
      ...COMMON_MODAL_PROPS,
    },
  },
  VerificationWithdrawal: {
    name: 'VerificationWithdrawal',
    priority: Infinity,
    modalProps: {
      ...COMMON_MODAL_PROPS,
    },
  },
  Templates: {
    name: 'Templates',
    priority: Infinity,
    modalProps: {
      ...COMMON_MODAL_PROPS,
    },
  },
  ChatReject: {
    name: 'ChatReject',
    priority: Infinity,
    modalProps: {
      ...COMMON_MODAL_PROPS,
    },
  },
  AcceptConfirm: {
    name: 'AcceptConfirm',
    priority: Infinity,
    modalProps: {
      ...COMMON_MODAL_PROPS,
    },
  },
  RestrictedAccess: {
    name: 'RestrictedAccess',
    priority: Infinity,
    modalProps: {
      ...COMMON_MODAL_PROPS,
      isUpper: true,
    },
  },
  BrowserNotifications: {
    name: 'BrowserNotifications',
    priority: Infinity,
    modalProps: {
      ...COMMON_MODAL_PROPS,
    },
  },
  UploadFiles: {
    name: 'UploadFiles',
    priority: Infinity,
    modalProps: {
      ...COMMON_MODAL_PROPS,
    },
  },
  InboxUpdateDetail: {
    name: 'InboxUpdateDetail',
    priority: Infinity,
  },
  InboxUpdatesModalContainer: {
    name: 'InboxUpdatesModalContainer',
    priority: Infinity,
  },
};

export default modalList;
